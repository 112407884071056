<template>
    <picture ref="picture">
        <slot>
            <template v-for="format in formats" :key="format">
                <template v-if="format !== formats[formats.length - 1]">
                    <source
                        :type="getTypeByFormat(format)"
                        :data-srcset="`${ src }.${ format }`"
                    >
                </template>
            </template>
            <img :data-src="`${ src }.${ formats[formats.length - 1] }`" :alt="alt" :title="title">
        </slot>
    </picture>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'LazyImage',

  props: {
    src: {
      type: String,
      required: false,
      default: () => null,
    },
    alt: {
      type: String,
      required: false,
      default: () => null,
    },
    title: {
      type: String,
      required: false,
      default: () => null,
    },
    formats: {
      type: Array,
      required: false,
      default: () => ['webp', 'png'],
    },
  },

  setup() {
    const picture = ref<InstanceType<typeof Element>>();

    const loadImage = (): void => {
      if (picture.value) {
        picture.value.querySelectorAll('img').forEach((el: HTMLElement) => {
          const element = el;
          element.setAttribute('src', element.dataset.src || '');
          element.removeAttribute('data-src');
        });

        picture.value.querySelectorAll('source').forEach((el: HTMLElement) => {
          const element = el;
          element.setAttribute('srcset', element.dataset.srcset || '');
          element.removeAttribute('data-srcset');
        });
      }
    };

    // eslint-disable-next-line max-len
    const handleIntersect = (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          if (picture.value) {
            observer.unobserve(picture.value);
          }
        }
      });
    };

    const createObserver = (): void => {
      const observer = new IntersectionObserver(handleIntersect, {
        root: null,
        threshold: 0,
      });

      if (picture.value) {
        observer.observe(picture.value);
      }
    };

    const getTypeByFormat = (format: string): string | null => {
      switch (format) {
        case 'webp':

          return 'image/webp';
        case 'png':

          return 'image/png';
        default:

          return null;
      }
    };

    onMounted(() => {
      if (window.IntersectionObserver) {
        createObserver();
      } else {
        loadImage();
      }
    });

    return {
      picture,
      getTypeByFormat,
    };
  },
});
</script>
