
import { defineComponent, computed } from 'vue';
import LazyImage from './LazyImage.vue';

export default defineComponent({
  name: 'SectionSpacer',

  components: {
    LazyImage,
  },

  props: {
    spacer: {
      type: Number,
      default: () => 1,
    },
    alt: {
      type: String,
      default: () => 'Milchwelle zum Trennen der Sektionen',
    },
    type: {
      type: String,
      default: () => 'img',
    },
  },

  setup(props) {
    const sizes: number[] = [
      3840,
      1920,
      1280,
      480,
    ];

    const types = {
      webp: 'image/webp',
      png: 'image/png',
    };

    const classes = computed(() => [
      `section-spacer-${props.type}`,
      `section-spacer-${props.type}--${props.spacer}`,
    ]);

    const getPath = (size: number, filex: string): string => `/img/milkspacer/${props.spacer}/${size}.${filex}`;

    return {
      sizes,
      types,
      classes,
      getPath,
    };
  },
});
