
import {
  defineComponent, onMounted, ref, onBeforeUnmount,
} from 'vue';
import SectionSpacer from './SectionSpacer.vue';
import AnimateScroll from '../utils/AnimateScroll';

export default defineComponent({
  components: {
    SectionSpacer,
  },
  props: {
    fullHeight: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    scrollToNext: {
      type: String,
      required: false,
      default: () => null,
    },
  },

  setup(props) {
    const hero = ref<InstanceType<typeof HTMLElement>>();
    const spacer = ref<InstanceType<typeof SectionSpacer>>();
    const row = ref<InstanceType<typeof HTMLElement>>();
    const btnStart = ref<InstanceType<typeof HTMLElement>>();

    const animateScroll = new AnimateScroll();

    const getHeaderHeight = (): number => {
      const header = document.querySelector('header');

      return header ? header.offsetHeight : 0;
    };

    const setPadding = (): void => {
      if (hero.value) {
        hero.value.style.paddingTop = `${getHeaderHeight()}px`;
      }
    };

    const setHeight = (): void => {
      if (row.value && spacer.value && btnStart.value) {
        row.value.style.height = '';
        if (window.innerWidth >= 768) {
          const fullHeight = window.innerHeight
            - (props.fullHeight ? 0 : window.innerHeight * 0.382)
            - getHeaderHeight()
            - (props.scrollToNext ? btnStart.value.offsetHeight : 0)
            - spacer.value.$el.offsetHeight;

          if (row.value.offsetHeight < fullHeight) {
            row.value.style.height = `${fullHeight}px`;
          }
        }
      }
    };

    const scrollToAboutSection = (): void => {
      animateScroll.scrollVerticalToElementById(props.scrollToNext, 80);
    };

    onMounted(() => {
      window.addEventListener('resize', setHeight);
      window.addEventListener('resize', setPadding);
      setHeight();
      setPadding();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setHeight);
      window.removeEventListener('resize', setPadding);
    });

    return {
      hero,
      row,
      spacer,
      btnStart,
      scrollToAboutSection,
    };
  },
});
