<template>
  <section ref="hero" class="hero">
    <div class="container-fluid text-center">
      <div class="row justify-content-center align-items-center" ref="row">
        <div class="col-12 col-md-10 align-self-center hero-content">
          <slot></slot>
        </div>
      </div>
      <div class="hero__btn-start" ref="btnStart" v-if="scrollToNext">
        <button @click="scrollToAboutSection">
          <slot name="scrollToNextLabel">Los gehts's</slot>
          <br>
          <i class="far fa-chevron-down"></i>
        </button>
      </div>
    </div>
    <section-spacer ref="spacer" :spacer="2"></section-spacer>
  </section>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, ref, onBeforeUnmount,
} from 'vue';
import SectionSpacer from './SectionSpacer.vue';
import AnimateScroll from '../utils/AnimateScroll';

export default defineComponent({
  components: {
    SectionSpacer,
  },
  props: {
    fullHeight: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    scrollToNext: {
      type: String,
      required: false,
      default: () => null,
    },
  },

  setup(props) {
    const hero = ref<InstanceType<typeof HTMLElement>>();
    const spacer = ref<InstanceType<typeof SectionSpacer>>();
    const row = ref<InstanceType<typeof HTMLElement>>();
    const btnStart = ref<InstanceType<typeof HTMLElement>>();

    const animateScroll = new AnimateScroll();

    const getHeaderHeight = (): number => {
      const header = document.querySelector('header');

      return header ? header.offsetHeight : 0;
    };

    const setPadding = (): void => {
      if (hero.value) {
        hero.value.style.paddingTop = `${getHeaderHeight()}px`;
      }
    };

    const setHeight = (): void => {
      if (row.value && spacer.value && btnStart.value) {
        row.value.style.height = '';
        if (window.innerWidth >= 768) {
          const fullHeight = window.innerHeight
            - (props.fullHeight ? 0 : window.innerHeight * 0.382)
            - getHeaderHeight()
            - (props.scrollToNext ? btnStart.value.offsetHeight : 0)
            - spacer.value.$el.offsetHeight;

          if (row.value.offsetHeight < fullHeight) {
            row.value.style.height = `${fullHeight}px`;
          }
        }
      }
    };

    const scrollToAboutSection = (): void => {
      animateScroll.scrollVerticalToElementById(props.scrollToNext, 80);
    };

    onMounted(() => {
      window.addEventListener('resize', setHeight);
      window.addEventListener('resize', setPadding);
      setHeight();
      setPadding();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setHeight);
      window.removeEventListener('resize', setPadding);
    });

    return {
      hero,
      row,
      spacer,
      btnStart,
      scrollToAboutSection,
    };
  },
});
</script>

<style lang="scss">
  @import 'src/scss/base';

  .hero {
    background-image: url('~@/assets/img/coffee.jpg');
    color: #FFFFFF;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(58, 45, 35);
      background: linear-gradient(180deg, rgba(58, 45, 35, 1) 0%, rgba(58, 45, 35, 0) 100%);
      z-index: 1;
    }

    > div {
      position: relative;
      z-index: 3;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60%;
      background: rgb(211, 116, 78);
      background: linear-gradient(0deg, rgba(211, 116, 78, 1) 0%, rgba(58, 45, 35, 0) 100%);
      z-index: 2;
    }

    .hero-content {
      padding: 5rem 0;
    }

    &__btn-start {
      text-align: center;

      > button {
        @include korolev-heavy-italic(30px);

        &, &:hover, &:focus {
          border: 0;
          box-shadow: none;
          background: transparent;
          color: #FFFFFF;
          outline: 0;
        }

        @keyframes animateHeroSVG {
          0% {
            top: 0;
          }
          50% {
            top: .33em;
          }
          100% {
            top: 0;
          }
        }

        svg {
          position: relative;
          top: 0;
          will-change: top;
          animation-name: animateHeroSVG;
          animation-duration: 2s;
          animation-iteration-count: infinite;
        }
      }
    }

    i.far {
      display: inline-block;
      font-size: inherit;
      height: 1em;
      overflow: visible;
      vertical-align: -.125em;
    }
  }
</style>
