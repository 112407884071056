<template>
    <div class="section-spacer" :class="classes">
        <LazyImage v-if="type === 'img'">
            <template v-for="(size, sizeinx) in sizes" :key="size">
                <template v-for="(type, filex) in types" :key="`${size}-${filex}`">
                    <source
                      v-if="sizeinx === sizes.length - 1"
                      :media="`(max-width: ${sizes[sizeinx]}px)`"
                      :type="type" :data-srcset="getPath(size, filex)"
                    >
                    <source
                      v-else-if="sizeinx === 0"
                      :media="`(min-width: ${sizes[sizeinx + 1] + 1}px)`"
                      :type="type" :data-srcset="getPath(size, filex)"
                    >
                    <source
                      v-else
                      :media="`(min-width: ${sizes[sizeinx + 1] + 1}px)
                      and (max-width: ${sizes[sizeinx]}px)`"
                      :type="type" :data-srcset="getPath(size, filex)">
                </template>
            </template>
            <img :data-src="getPath(sizes[sizes.length - 1], 'webp')" :alt="alt" loading="lazy">
        </LazyImage>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import LazyImage from './LazyImage.vue';

export default defineComponent({
  name: 'SectionSpacer',

  components: {
    LazyImage,
  },

  props: {
    spacer: {
      type: Number,
      default: () => 1,
    },
    alt: {
      type: String,
      default: () => 'Milchwelle zum Trennen der Sektionen',
    },
    type: {
      type: String,
      default: () => 'img',
    },
  },

  setup(props) {
    const sizes: number[] = [
      3840,
      1920,
      1280,
      480,
    ];

    const types = {
      webp: 'image/webp',
      png: 'image/png',
    };

    const classes = computed(() => [
      `section-spacer-${props.type}`,
      `section-spacer-${props.type}--${props.spacer}`,
    ]);

    const getPath = (size: number, filex: string): string => `/img/milkspacer/${props.spacer}/${size}.${filex}`;

    return {
      sizes,
      types,
      classes,
      getPath,
    };
  },
});
</script>

<style lang="scss">
    .section-spacer {
        width: 100%;
        overflow: visible;
        pointer-events: none;
        position: relative;

        &-img {
            img {
                display: block;
                width: 100%;
                height: auto;
                position: absolute;
                top: 100%;
            }

            &--1 {
                height: 9.633vw;

                img {
                    transform: translateY(-64%);
                }
            }

            &--2 {
                height: 9.25vw;

                img {
                    transform: translateY(-50%);
                }
            }
        }

        &-gap {
            &--1 {
                height: 5.41856vw;
            }

            &--2 {
                height: 9.25vw;
            }
        }
    }
</style>
